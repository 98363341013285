import Hidden from '@material-ui/core/Hidden'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { COUNTRY_AT } from 'lib/constants'
import { getCountry } from 'lib/utils'
import Link from 'next/link'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex'
  },
  logo: {
    verticalAlign: 'text-bottom',
    marginRight: '10px'
  }
}))

export default function Logo ({ className }) {
  const classes = useStyles()

  return (
    <Link href='/' passHref>
      <a className={`${classes.container} ${className}`} aria-label='Home'>
        <img
          data-test='logo-mark'
          src={getCountry() === COUNTRY_AT ? '/images/flagge.gif' : '/images/logomark-de.png'}
          width={getCountry() === COUNTRY_AT ? 37 : 44}
          height='27' alt='Logo'
          className={classes.logo}
        />
        <Hidden smDown>
          <img
            data-test='logo-type'
            src={`/images/logotype-${getCountry()}-white.png`}
            width='258' height='27'
            alt='Sendung verpasst'
            className={classes.logo}
          />
        </Hidden>
      </a>
    </Link>
  )
}
