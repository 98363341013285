import FormControl from '@material-ui/core/FormControl'
import Icon from '@material-ui/core/Icon'
import InputAdornment from '@material-ui/core/InputAdornment'
import { darken, alpha } from '@material-ui/core/styles/colorManipulator'
import makeStyles from '@material-ui/core/styles/makeStyles'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import fetch from 'cross-fetch'
import { AUTOCOMPLETE_MINCHARS } from 'lib/constants'
import { useRouter } from 'next/router'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  form: {
    flexGrow: 2,
    maxWidth: '400px'
  },
  searchFormControl: {
    display: 'flex',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.type === 'dark' ? alpha(theme.palette.common.white, 0.15) : darken(theme.palette.common.white, 0.05),
    '&:hover': {
      backgroundColor: theme.palette.type === 'dark' ? alpha(theme.palette.common.white, 0.25) : darken(theme.palette.common.white, 0.1)
    },
    width: '100%'
  },
  searchIcon: {
    padding: theme.spacing(1),
    marginRight: 0
  },
  searchInput: {
    padding: '2px'
  }
}))

export default function SearchForm ({ query, setQuery, className }) {
  const classes = useStyles()
  const router = useRouter()
  const [options, setOptions] = React.useState([])

  React.useEffect(() => {
    let active = true

    if (process.env.NEXT_PUBLIC_AUTOCOMPLETE_ENABLED === 'false' ||
        !query ||
        query.length < AUTOCOMPLETE_MINCHARS) {
      setOptions([])
      return undefined
    }

    async function callAutocomplete () {
      const response = await fetch('/api/autocomplete?q=' + query)
      const shows = await response.json()

      if (active) {
        if (shows.length > 0) {
          setOptions(query ? [query, ...shows] : shows)
        }
      }
    }
    callAutocomplete()

    return () => {
      active = false
    }
  }, [query])

  const handleSubmit = (query, event) => {
    if (event) event.preventDefault()

    if (!query) {
      return
    }
    setOptions([])

    router.push({
      pathname: '/search',
      query: { q: query }
    }).then(() => window.scrollTo(0, 0))
  }

  return (
    <form onSubmit={(e) => handleSubmit(query, e)} className={`${classes.form} ${className}`}>
      <FormControl className={classes.searchFormControl} fullWidth>
        <Autocomplete
          id='autocomplete'
          freeSolo
          autoComplete
          includeInputInList
          options={options}
          fullWidth
          inputValue={query}
          onChange={(event, newValue) => handleSubmit(newValue)}
          onInputChange={(event, newInputValue) => setQuery(newInputValue)}
          renderInput={(params) =>
            <TextField
              {...params}
              placeholder='Suche nach Videos...'
              inputProps={{
                ...params.inputProps,
                'aria-label': 'Suche nach Videos',
                maxLength: 100
              }}
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
                name: 'q',
                className: classes.searchInput,
                startAdornment: (
                  <InputAdornment position='start' className={classes.searchIcon}>
                    <Icon>search</Icon>
                  </InputAdornment>
                )
              }}
            />}
        />
      </FormControl>
    </form>
  )
}
