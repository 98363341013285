import Container from '@material-ui/core/Container'
import makeStyles from '@material-ui/core/styles/makeStyles'
import SVNavbar from 'components/navbar/Navbar'
import Footer from './Footer'

const useStyles = makeStyles((theme) => ({
  offset: theme.mixins.toolbar,
  mainContent: {
    paddingTop: theme.spacing(3)
  },
  mainHeaderContainer: {
    display: 'flex'
  }
}))

export default function Layout ({ children, header, headerColor, search }) {
  const classes = useStyles()

  return (
    <>
      <SVNavbar search={search} />

      <main className={classes.main}>
        <div className={classes.offset} />
        {header &&
          <div className={classes.mainHeader} style={{ background: headerColor }}>
            <Container maxWidth='lg' className={classes.mainHeaderContainer}>
              {header}
            </Container>
          </div>}
        <Container maxWidth='lg' className={classes.mainContent}>
          {children}
        </Container>
      </main>

      <Footer />
    </>
  )
}
